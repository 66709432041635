<template>
  <div>
    <v-row>
      <v-col
        cols="2"
        offset="5"
      >
        <v-menu
          v-model="showCalendar"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="300"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="selectedMonth"
              prepend-inner-icon="mdi-calendar"
              readonly
              :clearable="false"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="selectedMonth"
            type="month"
            @input="showCalendar = false"
          />
        </v-menu>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="consumptionReportData"
      :items-per-page="-1"
      hide-default-footer
      class="eg-table-bnone eg-table-td-py-md mt-2 px-4"
    />
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import moment from 'moment-timezone'

  import util from '@/utils'
  import { showErrorDialog } from '@/utils/swalDialog'

  export default {
    data () {
      return {
        showCalendar: false,
        selectedMonth: new Date(),
        consumptionReportData: [],
        headers: [{
          text: this.$t('consumption.deviceId'),
          value: 'deviceId',
          width: 200,
        }, {
          text: this.$t('consumption.value180'),
          value: 'consumptionValue180',
          width: 200,
        }, {
          text: this.$t('consumption.value181'),
          value: 'consumptionValue181',
          width: 200,
        }, {
          text: this.$t('consumption.minMaxPowerPL1'),
          value: 'minMaxPowerPL1',
          width: 200,
        }, {
          text: this.$t('consumption.minMaxPowerPL2'),
          value: 'minMaxPowerPL2',
          width: 200,
        }, {
          text: this.$t('consumption.minMaxPowerPL3'),
          value: 'minMaxPowerPL3',
          width: 200,
        }],
      }
    },

    watch: {
      selectedMonth (value) {
        if (!value) {
          return
        }

        this.loadConsumptionData()
      },
    },

    mounted () {
      this.selectedMonth = moment().format('YYYY-MM')
    },

    methods: {
      ...mapActions({
        setLoading: 'setLoading',
        setLoadingText: 'setLoadingText',
        loadMonthlyConsumptionData: 'loadMonthlyConsumptionData',
      }),

      async loadConsumptionData () {
        this.setLoadingText(`${this.$t('common.loading')} ${this.$t('common.data')}...`)
        this.setLoading(true)

        try {
          const response = await this.loadMonthlyConsumptionData(this.selectedMonth)
          this.consumptionReportData = (response.result || []).map(item => ({
            ...item,
            minMaxPowerPL1: `${item.minPowerPL1} / ${item.maxPowerPL1}`,
            minMaxPowerPL2: `${item.minPowerPL2} / ${item.maxPowerPL2}`,
            minMaxPowerPL3: `${item.minPowerPL3} / ${item.maxPowerPL3}`,
          }))
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
          this.consumptionReportData = []
        }

        this.setLoading(false)
      },
    },
  }
</script>
