<template>
  <base-card>
    <v-card-text>
      <v-tabs
        v-model="tab"
        background-color="blue-grey darken-1"
        color="orange"
        active-class="white--text"
        grow
        dark
      >
        <v-tabs-slider />
        <v-tab key="monthly">
          {{ $t('consumption.monthly') }}
        </v-tab>

        <v-tab key="daily">
          {{ $t('consumption.daily') }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item key="monthly">
          <monthly-view-data />
        </v-tab-item>

        <v-tab-item key="daily">
          <daily-view-data />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </base-card>
</template>

<script>
  import MonthlyViewData from './MonthlyViewData'
  import DailyViewData from './DailyViewData'

  export default {
    metaInfo: {
      title: 'Consumptions',
    },

    components: {
      MonthlyViewData,
      DailyViewData,
    },

    data () {
      return {
        tab: 0,
      }
    },
  }
</script>
