<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="2"
        offset-sm="3"
      >
        <v-menu
          v-model="showCalendar"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="300"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="selectedDate"
              prepend-inner-icon="mdi-calendar"
              readonly
              :clearable="false"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="selectedDate"
            @input="showCalendar = false"
          />
        </v-menu>
      </v-col>

      <v-col
        cols="12"
        sm="3"
      >
        <v-select
          v-model="selectedDeviceId"
          :items="deviceIdList"
          label="Device"
          hide-details
          clearable
        />
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="consumptionReportData"
      :items-per-page="paginationLimit"
      hide-default-footer
      class="eg-table-bnone eg-table-td-py-md mt-2 px-4"
    >
      <template v-slot:item.receivedAt="{item}">
        <span>{{ util.formatDateTime(item.receivedAt, 'hh:mm A') }}</span>
      </template>
    </v-data-table>

    <v-card-actions class="justify-center">
      <v-pagination
        v-model="currentPage"
        :length="totalPages"
        color="primary"
        :total-visible="7"
        circle
      />
    </v-card-actions>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import moment from 'moment-timezone'

  import util from '@/utils'
  import { showErrorDialog } from '@/utils/swalDialog'

  export default {
    data () {
      return {
        showCalendar: false,
        selectedDate: new Date(),
        deviceIdList: [],
        selectedDeviceId: null,
        consumptionReportData: [],
        headers: [{
          text: this.$t('consumption.receivedAt'),
          value: 'receivedAt',
          width: 200,
        }, {
          text: this.$t('consumption.value180'),
          value: 'consumptionValue180',
          width: 200,
        }, {
          text: this.$t('consumption.value181'),
          value: 'consumptionValue181',
          width: 200,
        }, {
          text: this.$t('consumption.powerPL1'),
          value: 'powerPL1',
          width: 200,
        }, {
          text: this.$t('consumption.powerPL2'),
          value: 'powerPL2',
          width: 200,
        }, {
          text: this.$t('consumption.powerPL3'),
          value: 'powerPL3',
          width: 200,
        }],
        paginationLimit: 30,
        currentPage: 1,
        totalPages: 0,
        util: util,
      }
    },

    watch: {
      selectedDate (value) {
        if (!value) {
          this.resetData()
          return
        }

        this.loadDeviceIds()
      },

      selectedDeviceId (value) {
        if (!value) {
          this.resetData()
          return
        }

        this.loadConsumptionData()
      },

      currentPage(val) {
        if (!val) {
          return
        }

        this.loadConsumptionData(val)
      }
    },

    mounted () {
      this.selectedDate = moment().format('YYYY-MM-DD')
    },

    methods: {
      ...mapActions({
        setLoading: 'setLoading',
        setLoadingText: 'setLoadingText',
        loadConsumptionMonthlyDeviceIds: 'loadConsumptionMonthlyDeviceIds',
        loadDailyConsumptionData: 'loadDailyConsumptionData',
      }),

      resetData () {
        this.deviceIdList = []
        this.consumptionReportData = []
        this.currentPage = 1
        this.totalPages = 0
        this.selectedDeviceId = null
      },

      async loadDeviceIds () {
        this.setLoadingText(`${this.$t('common.loading')} ${this.$t('common.data')}...`)
        this.setLoading(true)

        try {
          const month = moment(this.selectedDate).format('YYYY-MM')
          const response = await this.loadConsumptionMonthlyDeviceIds(month)
          this.deviceIdList = response.deviceIds || []

          if (this.deviceIdList.length > 0) {
            this.selectedDeviceId = this.deviceIdList[0]

            this.loadConsumptionData()
          } else {
            this.resetData()
          }
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)

          this.resetData()
        }

        this.setLoading(false)
      },

      async loadConsumptionData (currentPage = 1) {
        this.currentPage = currentPage
        const pagination = {
          offset: (this.currentPage - 1) * this.paginationLimit,
          limit: this.paginationLimit,
        }

        this.setLoadingText(`${this.$t('common.loading')} ${this.$t('common.data')}...`)
        this.setLoading(true)

        try {
          const response = await this.loadDailyConsumptionData({
            date: this.selectedDate,
            deviceId: this.selectedDeviceId,
            ...pagination,
          })

          this.consumptionReportData = response.rows || []
          this.totalPages = Math.ceil(response.count / this.paginationLimit)
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
          this.consumptionReportData = []
        }

        this.setLoading(false)
      },
    },
  }
</script>
